// organize-imports-ignore
import React from "react";
import ReactDOM from "react-dom";
import { Router, Switch, Route as UnwrappedRoute } from "react-router-dom";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom-v5-compat";

import { AppUrlOpen, App as CapApp, AppState as CapAppState } from "@capacitor/app";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import * as Sentry from "@sentry/react";
import { History } from "history";
import { Dimmer, Transition } from "semantic-ui-react";

import { ViewportContextProvider } from "~/components/common/ViewportContext";
import FullPageError from "~/components/error/FullPageError";
import Lowda, { StaticLowda } from "~/components/fellow/Lowda";
import growthbook from "~/lib/ab/growthbook";
import { AuthProvider } from "~/lib/auth/index";
import { initBrowserSupports } from "~/lib/browserSupport";
import { ensureAppUpdated } from "~/lib/ensureAppUpdated";
import "~/lib/extendedMoment";
import history from "~/lib/history";
import { configureIntune } from "~/lib/intune/intune";
import { isDesktopApp, isNative } from "~/lib/utils";
import { desktopVersion } from "~/lib/version";

// Import styles in a specific order to avoid Semantic overriding our styles
import "~/css/semantic/semantic.css";

import "~/css/App.less";
import "~/ui/text-styles.css";
import "~/ui/variables.css";

window.HISTORY = history;

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
const Route = Sentry.withSentryRouting(UnwrappedRoute);

// Sentry configuration
if (window && window.SENTRY_DSN_PUBLIC) {
	Sentry.init({
		dsn: window.SENTRY_DSN_PUBLIC,
		environment: window.SENTRY_ENV,
		release: window.APP_VERSION,
		// If we load the electron sentry in this context before browser sentry loads
		// auto session tracking breaks the desktop app.
		autoSessionTracking: !isDesktopApp,
		maxBreadcrumbs: 100,
		beforeBreadcrumb(crumb) {
			if (
				crumb.category === "xhr" &&
				["https://api.segment.io", "https://stats.g.doubleclick.net", "https://in.hotjar.com"].some(e =>
					crumb.data?.url?.startsWith(e),
				)
			) {
				return null;
			}
			return crumb;
		},
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
		],
		tracesSampleRate: process.env.NODE_ENV === "production" ? 0.05 : 1.0,
	});

	const currentScope = Sentry.getCurrentScope();
	currentScope.setTag("client_session_id", window.CLIENT_SESSION_ID);
	if (window.INITIAL_STATE?.user?.id) {
		currentScope.setUser({
			email: window.INITIAL_STATE.user.email,
			id: String(window.INITIAL_STATE.user.id),
		});
	}
	try {
		const resolvedDesktopVersion = desktopVersion();
		if (resolvedDesktopVersion) {
			currentScope.setTag("desktop_app_version", resolvedDesktopVersion.join("."));
		}
	} catch (error) {
		Sentry.captureException(error);
	}
}

interface ErrorBoundaryState {
	error: boolean;
}

class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
	state: ErrorBoundaryState = { error: false };

	componentDidCatch(error: Error) {
		console.error("Router caught error:", error);

		if (window && window.SENTRY_DSN_PUBLIC) {
			Sentry.captureException(error);
		}

		return this.setState({ error: true });
	}

	render() {
		if (this.state.error) {
			return (
				<Transition visible>
					<Dimmer active inverted>
						<FullPageError message={"Oops - Fellow failed to load"} />
					</Dimmer>
				</Transition>
			);
		}

		return this.props.children;
	}
}

async function appListeners(history: History) {
	CapApp.addListener("appStateChange", (state: CapAppState) => {
		console.debug("App state changed. Is active?", state.isActive);
	});

	CapApp.addListener("appUrlOpen", (data: AppUrlOpen) => {
		if (data.url.startsWith("msal")) {
			// these links are microsoft authentication links, they're handled separately by the capacitor-oauth2 plugin.
			return;
		}
		const url = new URL(data.url);

		let pathname = url.pathname;

		// Login tickets require a full browser navigation to work, a history push isn't sufficient for the authentication
		if (window.location.host !== url.host || url.pathname.startsWith("/auth/login/ticket")) {
			window.location.href = url.href;
			return;
		}

		if (url.pathname.startsWith("/auth/login")) {
			const next = url.searchParams.get("next");
			if (next != null) {
				pathname = decodeURIComponent(next);
			}
		}

		history.push(pathname);
	});
}

function onServiceWorkerMessage(event: MessageEvent) {
	if (typeof event.data !== "object" || event.data === null) {
		return;
	}

	const { action } = event.data;

	if (action === "delegationStatusChange") {
		setTimeout(() => {
			window.location.reload();
		}, 1000);
	}
}

function serviceWorkerListeners() {
	const sw = window.navigator.serviceWorker;
	if (sw) {
		sw.addEventListener("message", onServiceWorkerMessage);
	}
}

const Email = React.lazy(() => configureIntune(ensureAppUpdated(import("~/email"))));
const Auth = React.lazy(() => ensureAppUpdated(import("~/auth")));
const Onboarding = React.lazy(() => configureIntune(ensureAppUpdated(import("~/onboarding"))));
const Public = React.lazy(() => configureIntune(ensureAppUpdated(import("~/public"))));
const Apps = React.lazy(() => configureIntune(ensureAppUpdated(import("~/apps"))));
const App = React.lazy(() => configureIntune(ensureAppUpdated(import("~/components/App"))));
const Guest = React.lazy(() => configureIntune(ensureAppUpdated(import("~/guest"))));
const Wrapped2020 = React.lazy(() => configureIntune(ensureAppUpdated(import("~/wrapped-2020"))));
const Recap2021 = React.lazy(() => configureIntune(ensureAppUpdated(import("~/recap-2021"))));
const Recap2022 = React.lazy(() => configureIntune(ensureAppUpdated(import("~/recap-2022"))));

if ("serviceWorker" in navigator && navigator.serviceWorker.register) {
	window.addEventListener("load", function () {
		if (!window.Cypress) {
			navigator.serviceWorker.register("/serviceWorkerRoot.js", {});
		}
	});
}

const Root = () => {
	if (isNative) appListeners(history);
	serviceWorkerListeners();

	return (
		<AuthProvider>
			<ViewportContextProvider>
				<GrowthBookProvider growthbook={growthbook}>
					<Router history={history}>
						<ErrorBoundary>
							<React.Suspense fallback={<StaticLowda />}>
								<Switch>
									<Route path="/emails/" component={Email} />
									<Route path="/auth/" component={Auth} />
									<Route path="/o/" component={Onboarding} />
									<Route path="/public/" component={Public} />
									<Route path="/apps/" component={Apps} />
									<Route path="/2020-recap/" component={Wrapped2020} />
									<Route path="/recap-2021/" component={Recap2021} />
									<Route path="/recap-2022/" component={Recap2022} />
									<Route path="/guest/" component={Guest} />
									<Route path="/" component={App} />
								</Switch>
							</React.Suspense>
							<Lowda />
						</ErrorBoundary>
					</Router>
				</GrowthBookProvider>
			</ViewportContextProvider>
		</AuthProvider>
	);
};
function main(): void {
	ReactDOM.render(<Root />, document.getElementById("root"));
}

main();

if (window) {
	window.addEventListener("keydown", (e: KeyboardEvent) => {
		if ([8, 32].includes(e.keyCode) && window.document && e.target == window.document.body) {
			e.preventDefault();
		}
	});

	initBrowserSupports();
	document.documentElement.classList.toggle("Nav--ReducedMainWidth");
}
