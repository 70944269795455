import cookie from "js-cookie";
import { debounce, mapKeys, merge, noop } from "lodash";

import { desktopVersion as getDesktopVersion } from "~/lib/version";

import { inCalendar, inVideocall } from "./browserExtension";
import store from "./store";
import { getPlatform, isNative } from "./utils";

const UTM_STORE_KEY = "fellow_referrer";
const UTM_LAST_TOUCH_STORE_KEY = "fellow_referrer_last_touch";

function getCommonOptions() {
	return { integrations: { "Stitch Data": false } };
}

function getCommonProperties() {
	/* eslint-disable camelcase */
	const fetchedDesktopVersion = getDesktopVersion();
	return {
		is_desktop_app: !!fetchedDesktopVersion,
		...(fetchedDesktopVersion ? { desktop_app_version: `FellowElectron:${fetchedDesktopVersion.join(".")}` } : {}),
		capacitor_is_native: isNative,
		capacitor_platform: getPlatform(),
		is_embedded: inVideocall || inCalendar,
		client_session_id: window.CLIENT_SESSION_ID,
	};
	/* eslint-enable camelcase */
}

export function dispatchAnalyticsPage(category?: string, name?: string, properties: object = {}, options: object = {}) {
	const callTypeDefaultOptions = { integrations: { "Facebook Pixel": true } };

	const actualProperties = merge(getCommonProperties(), properties);
	const actualOptions = merge(getCommonOptions(), callTypeDefaultOptions, options);

	window.analytics.page(category, name, actualProperties, actualOptions);
}

export function dispatchAnalyticsTrack(event: string, properties: object = {}, options: object = {}) {
	if (process.env.NODE_ENV !== "production") {
		console.debug("[dispatchAnalyticsTrack]", event, properties, options);
	}
	const callTypeDefaultOptions = { integrations: { "Facebook Pixel": false, Intercom: false } };

	const actualProperties = merge(getCommonProperties(), properties);
	const actualOptions = merge(getCommonOptions(), callTypeDefaultOptions, options);

	window.analytics.track(event, actualProperties, actualOptions);
}

export function resetAnalyticsUser() {
	window.heap?.resetIdentity();
	window.analytics.reset();
}

const UTM_PARAMS_OF_INTEREST = [
	"utm_campaign",
	"utm_medium",
	"utm_source",
	"utm_content",
	"utm_term",
	"gclid",
	"fbclid",
	"msclkid",
	"clickid",
];

const COOKIES_OF_INTEREST = [
	"_ga",
	"_gat",
	"_gid",
	"_gcl_au",
	"_gac",
	"_fbp",
	"_fbc",
	"_uetsid",
	"_uetvid",
	"__hstc",
	"__hssc",
	"__hssrc",
];

export function setReferringEntities() {
	const existing = store.has(UTM_STORE_KEY);

	if (!existing) {
		const result: Record<string, string> = {};
		const params = new URLSearchParams(window.location.search);
		for (const [name, value] of params.entries()) {
			if (UTM_PARAMS_OF_INTEREST.includes(name.toLowerCase())) {
				result[name.toLowerCase()] = value;
			}
		}
		store.set(UTM_STORE_KEY, result);
	}
}

export function getCookiesOfInterest() {
	const result: Record<string, string> = {};
	for (const name of COOKIES_OF_INTEREST) {
		const value = cookie.get(name);
		if (value) {
			result[name] = value;
		}
	}
	return result;
}

export function getReferringEntities(): Record<string, string> {
	const referrer = store.get(UTM_STORE_KEY, {});
	const ltReferrer = store.get(UTM_LAST_TOUCH_STORE_KEY, {});
	return merge(
		referrer,
		mapKeys(ltReferrer, (value, key) => `lt_${key}`),
	);
}

const debouncedInteractionTracker = debounce(
	() => {
		let data = new FormData();

		data.append("outer_height", window.outerHeight.toString());
		data.append("outer_width", window.outerWidth.toString());
		data.append("inner_height", window.innerHeight.toString());
		data.append("inner_width", window.innerWidth.toString());

		data.append("screen_height", window.screen.height.toString());
		data.append("screen_width", window.screen.width.toString());
		data.append("screen_avail_height", window.screen.availHeight.toString());
		data.append("screen_avail_width", window.screen.availWidth.toString());

		data.append("device_pixel_ratio", window.devicePixelRatio.toString());
		data.append("has_vertical_scrollbar", (document.body.scrollHeight > window.innerHeight).toString());
		data.append("has_horizontal_scrollbar", (document.body.scrollWidth > window.innerWidth).toString());

		fetch("/whisper/neon/", {
			method: "POST",
			body: data,
			headers: {
				"x-client-session-id": window.CLIENT_SESSION_ID,
				"x-csrftoken": window.CSRF_TOKEN,
				"x-requested-with": "XMLHttpRequest",
			},
		}).catch(noop);
	},
	900000,
	{ leading: true },
); // 15 minutes in milliseconds

document.addEventListener("click", debouncedInteractionTracker);
