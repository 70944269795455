import React from "react";
import { RouteComponentProps, StaticContext } from "react-router";

import { MsAuthPlugin } from "@recognizebv/capacitor-plugin-msauth";
import * as Sentry from "@sentry/browser";

import { signOut } from "~/lib/auth";
import { BASE_CONFIG } from "~/lib/auth/nativeOffice";
import { isAndroidApp } from "~/lib/utils";
import { LoadingSpinner } from "~/ui";

import styles from "./intune.module.css";

function ActivatingIntune({ text, extendedStatus }: { text: string; extendedStatus?: string }) {
	return (
		<div className={styles.box}>
			<LoadingSpinner className={styles.spinner} inline dimmer={false} />
			<p className={styles.loudText}>{text}</p>
			<p className={styles.quietText}>{extendedStatus}</p>
		</div>
	);
}

let intuneActive = false;

/**
 * This function can't be used before the Intune code below has run.
 * This generally happens in router.tsx fairly early on but not immediately.
 */
export function isIntuneActive() {
	return intuneActive;
}

function InitIntune() {
	const [{ statusText, extendedStatus }, setStatus] = React.useState<{ statusText: string; extendedStatus?: string }>(
		() => ({ statusText: "Activating Intune" }),
	);

	React.useEffect(() => {
		(async () => {
			const { Intune } = await import("@fellow/intune");

			if (!window.INITIAL_STATE.user?.email) {
				setStatus({ statusText: "Registration failed", extendedStatus: "No email to register with Intune" });
				return;
			}
			const email = window.INITIAL_STATE.user.email;
			const currentMSALAccount = await MsAuthPlugin.getCurrentAccount(BASE_CONFIG);

			// we need to check if the MSAL account email matches email we want to register with intune
			if (currentMSALAccount?.username !== window.INITIAL_STATE.user.email) {
				setStatus({ statusText: "Signing out" });
				console.log("Triggering signout due to mismatched MSAL account email and user email.");
				// If it doesn't we have the user sign in again, they'll then sign in with MSAL if their app is up to date
				// for now we won't handle an outdated app as we don't expect intune has not yet been deployed with a customer
				signOut(true);
				return;
			}

			const enrollmentResultListener = (status: { statusCode: number; errorString: string }) => {
				Sentry.captureMessage("Intune enrollment result", {
					extra: status,
				});
				if (status.statusCode === 203) {
					Intune.loginAndEnrollAccount({ email });
					setStatus({ statusText: "Starting Intune Login" });
				} else {
					setStatus({
						statusText: "Intune Enrollment Failed",
						extendedStatus: `Initial enroll failed: ${status.statusCode}, "${status.errorString}"`,
					});
				}
			};

			Intune.addListener("enrollmentResult", enrollmentResultListener);

			await Intune.registerAndEnrollAccount({ email });
			setStatus({ statusText: "Enrolling in Intune" });
		})();
	}, [setStatus]);

	return <ActivatingIntune text={statusText} extendedStatus={extendedStatus} />;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function configureIntune<T extends React.ComponentType<any>>(
	suspendedElement: Promise<{ default: T }>,
): Promise<{ default: T | React.FunctionComponent<RouteComponentProps<{}, StaticContext, {} | null | undefined>> }> {
	if (!window.INITIAL_STATE.intuneRequired) {
		return await suspendedElement;
	}
	const { Intune } = await import("@fellow/intune");

	if (isAndroidApp || !(await Intune.isSupportedPlatform()).supported) {
		return await suspendedElement;
	}

	const { accountIds } = await Intune.getEnrolledAccountIds();

	if (
		window.INITIAL_STATE.intuneRequiredAccountId &&
		accountIds?.includes(window.INITIAL_STATE.intuneRequiredAccountId)
	) {
		// intune is configured, with the correct account. Load the app
		intuneActive = true;
		return await suspendedElement;
	} else if (!window.INITIAL_STATE.intuneRequiredAccountId && accountIds.length > 0) {
		// Intune is configured, and any account is acceptable. (this happens with gsuite accounts for example)
		intuneActive = true;
		return await suspendedElement;
	} else {
		// Intune is either not configured, or the wrong account is configured.
		return { default: InitIntune };
	}
}
